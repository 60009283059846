@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.consultation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-dark;
}

.wrapper {
  color: $primary-white;
  background-color: $secondary-gray2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: $primary-width-mobile;
  margin-top: 40px;
  padding: 0 0px 10px 0px;
  @include tablet {
    width: $primary-width-tablet;
    padding-bottom: 30px;
  }
  @include laptop {
    margin-top: 140px;
    width: $primary-width-laptop;
    padding-bottom: 40px;
  }
}

.h1 {
  margin: 16px 0 0;
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 44px;

  @include tablet {
    font-size: 1.75rem;
  }
  @include laptop {
    margin: 40px 0 24px;
    font-size: 2rem;
  }
}

.paragraph {
  font-size: $primary-font-size-sm;
  text-align: center;
  line-height: 24px;
  font-family: "Noto Sans";
  font-weight: 400;
  opacity: 60%;

  @include tablet {
    font-size: $primary-font-size-md;
    margin: 0 0 40px;
  }
}
.inputIbutton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include laptop {
    display: flex;
    flex-direction: row;
  }
}
.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include tablet {
    display: flex;
    flex-direction: row;
  }
}
.inputName {
  outline: 0;
  border: 1px solid #eeba34;
  color: $primary-white;
  font-size: $primary-font-size-sm;
  margin: 0.625rem;
  width: 264px;
  padding: 8px;
  background-color: $secondary-gray2;
  @include tablet {
    padding: 10px;
    width: 300px;
    font-size: $primary-font-size-md;
  }
  @include laptop {
    padding: 12px;
    width: 280px;
    font-size: $primary-font-size-md;
  }
}
.error {
  color: red;
  margin: 0 10px;
}
.inputPhone {
  outline: 0;
  border: 1px solid #eeba34;
  color: $primary-white;
  font-size: $primary-font-size-sm;
  margin: 0.625rem;
  width: 264px;
  padding: 8px;
  background-color: $secondary-gray2;
  @include tablet {
    padding: 10px;
    width: 300px;
    font-size: $primary-font-size-md;
  }
  @include laptop {
    padding: 12px;
    width: 350px;
    font-size: $primary-font-size-md;
  }
}

.buttonSend {
  width: 6.126rem;
  height: 32px;
  margin: 1.25rem 0 1.25rem 0;
  color: $secondary-font-color-black;
  border: none;
  background: linear-gradient(
    99.45deg,
    #eeba34 25.18%,
    #ffd465 37.87%,
    #ffc42c 46.89%,
    #ffd465 55.24%,
    #eeba34 71.6%,
    #f8b638 86.63%
  );
  @include tablet {
    width: 10.5rem;
    height: 44px;
  }
}
