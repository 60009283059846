@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-dark;
}

.wrapper {
  color: $primary-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $primary-width-mobile;
  margin: 1rem 0 60px 0;
  overflow: hidden;
  @include tablet {
    width: $primary-width-tablet;
  }
  @include laptop {
    margin-top: 140px;
    margin-bottom: 140px;
    width: $primary-width-laptop;
  }
}

.h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: $heading-primary-font-size-sm;
  font-weight: 600;
  font-family: "Noto Sans";
  line-height: 49px;
  @include tablet {
    margin-bottom: 40px;
    font-size: $heading-primary-font-size-md;
  }
  @include laptop {
    font-size: $heading-primary-font-size-lg;
  }
}

.opacity{
  opacity: 60%;
}

.adresAndMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20.5rem;

  @include tablet {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include laptop {
    flex-direction: row;
  }
}

.contacts {
  border: 1px solid #ffd465;
  width: 328px;
  padding: 0.5625rem;
  @include tablet {
    width: $primary-width-tablet;
    padding: 2rem;
  }
  @include laptop {
    width: 570px;
    margin-left: 10px;
    padding: 1.875rem;
  }
}

.h2 {
  font-family: "Noto Sans";
  font-weight: 600;
  line-height: 33px;
  padding-top: 0.625rem;
  margin-top: 0;
  margin-bottom: 0.9375rem;
  font-size: 1rem;
  @include tablet {
    font-size: 1.5rem;
  }
}

.connection {
  display: flex;
}

.phoneiadres {
  font-size: $primary-font-size-sm;
  width: 100%;
  @include tablet {
    font-size: $primary-font-size-md;
  }
}

.phone {
  display: flex;
  // gap: 1rem;
  white-space: nowrap;

  img {
    margin-right: 0.7rem;
  }
}

.adres {
  display: flex;
  width: 100%;
  white-space: nowrap;
  justify-content: space-between;

  img {
    margin-right: 0.7rem;
  }
}

.social {
  display: flex;

  align-items: center;

  margin-left: auto;
  // @include laptop {
  //   border-right: 1px solid;
  // }
}

.facebook {
  width: 1.5rem;
  margin-right: 0.5rem;
  @include tablet {
    width: 2rem;
    margin-right: 10px;
  }
}

.application {
  background-color: $secondary-gray2;
  width: 100%;
  order: 2;
  margin-top: 1.25rem;
  text-align: center;
  border: 1px solid transparent;
  @include tablet {
    width: $primary-width-tablet;
    padding: 1.25rem;
  }
  @include laptop {
    margin-top: 50px;
    width: 36.3rem;
    text-align: start;
    padding: 1.875rem;
  }
}

.paragraph {
  font-size: $primary-font-size-sm;
  line-height: 24px;
  font-weight: 400;
  opacity: 60%;
  
  @include tablet {
    font-size: $primary-font-size-md;
  }
}

.btnInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  width: 100%;

  @include laptop {
    display: flex;
    flex-direction: row;
  }
}
.inputs {
  display: flex;
  flex-direction: column;
  @include tablet {
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
  @include laptop {
    display: flex;
    flex-direction: column;
  }
}

.input {
  outline: 0;
  border: 1px solid #eeba34;
  color: $primary-white;
  margin-bottom: 0.625rem;
  width: 264px;
  padding: 8px;
  background-color: $secondary-gray2;
  @include tablet {
    width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: row;
  }
  @include laptop {
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
  }
}
.error {
  color: red;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.625rem 0 25px 0;

  @include laptop {
    display: flex;
    justify-content: end;
    margin-top: auto;
    margin-bottom: 9px;
    // margin-bottom: 1.3rem;
  }
}

.button {
  width: 6.126rem;
  height: 32px;
  margin: 0;
  color: $secondary-font-color-black;
  border: none;
  background: linear-gradient(
    99.45deg,
    #eeba34 25.18%,
    #ffd465 37.87%,
    #ffc42c 46.89%,
    #ffd465 55.24%,
    #eeba34 71.6%,
    #f8b638 86.63%
  );
  @include tablet {
    width: 10.5rem;
    height: 2.75rem;
  }
}

.map {
  display: block;
  border: 2px solid transparent;
  margin-top: 1.25rem;
  height: 10rem;
  width: 350px;
  margin-top: 1.2rem auto 0 auto;
  @include tablet {
    width: $primary-width-tablet;
    height: 18.75rem;
  }
  @include laptop {
    width: 35rem;
    height: 35rem;
    margin-left: 10px;
    margin-top: 0;
  }
}
