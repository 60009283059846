@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.advantages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url(../img/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper {
  color: $primary-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: $primary-width-mobile;
  margin-top: 1.5rem;
  padding-bottom: 40px;
  overflow: hidden;

  @include tablet {
    margin-top: 40px;
    width: $primary-width-tablet;
  }
  @include laptop {
    width: $primary-width-laptop;
  }
  .text {
    text-align: center;
    overflow: hidden;
    line-height: 24px;
  }

  .h1 {
    font-size: $heading-primary-font-size-sm;
    margin: 0;
    font-weight: 600;
    font-family: "Noto Sans";
    line-height: 49px;
    @include tablet {
      font-size: $heading-primary-font-size-md;
    }
    @include laptop {
      font-size: $heading-primary-font-size-lg;
    }
  }
}
.slider_swiper {
  .swiper-wrapper {
    padding-bottom: 25px;
  }
}
.sliderWrapper {
  .swiper-wrapper {
    padding-bottom: 40px;
  }
  :global(.swiper-pagination) {
    bottom: 0px !important;
    :global(.swiper-pagination-bullet) {
      background-color: #ffc42c;
      width: 24px;
      height: 2px;
      border-radius: 0;
      @include tablet {
        width: 40px;
      }
    }
  }
  :global(.swiper-wrapper) {
    padding-bottom: 25px;
  }
}

.buttons{
  margin: 10px 0 0 ;
  @include tablet {
  margin: 30px 0 0;
    
  }
  @include laptop {
  }
}
.next,
.prev {
  background-color: transparent;
  border: none;
}
.paragraph {
  display: none;
  @include tablet {
    font-family: "Noto Sans";
    opacity: 60%;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 40px;
    display: block;
  }
  @include laptop {
    font-family: "Noto Sans";
    opacity: 70%;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 40px;
    display: block;
  }
}

.sliderWrapper {
  width: 100%;
  margin-top: 1.25rem;
}

.card {
  background-color: $secondary-black;
  text-align: center;
  border: 1px solid #eeba34;
  padding: 0.9375rem 0px 0.9375rem 0px;
  width: 100%;
  box-sizing: border-box;
}

.h2 {
  font-family: "Noto Sans";
  font-size: 13px;
  margin: 16px 0;
  font-weight: 500;
  line-height: 18px;
  
  @include tablet {
    font-size: 1.125rem;
  }
}

.paragraph2 {
  font-size: 10px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 21px;
  opacity: 70%;

  @include tablet {
    font-size: $primary-font-size-sm;
  }
}

.imgAdvantages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.75rem;
  gap: 2rem;
  width: 100%;
  @include tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @include laptop {
    margin-top: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.parkingcomand {
  position: relative;
  width: $primary-width-mobile;
}

.parking {
  position: absolute;
  top: 11rem;
  left: 0.625rem;
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;

  @include tablet {
    top: 14.5rem;
    left: 0.625rem;
    font-size: 20px;
  }

  @include laptop {
    top: 20rem;
    left: 0.625rem;
  }
}

.parkingImg {
  width: 100%;
  display: block;
  object-fit: cover;
  @include tablet {
    width: 100%;
    height: 300px;
  }
  @include laptop {
    width: 588px;
    height: 400px;
  }
}
.closedYardImg {
  width: 175px;
  height: 120px;
  display: block;
  object-fit: cover;
  margin: 10px;
  @include tablet {
    width: 255px;
    height: 138px;
  }
  @include laptop {
    width: 283px;
    height: 188px;
    margin: 0;
  }
}
.playgroundImg {
  width: 175px;
  height: 120px;
  display: block;
  object-fit: cover;
  margin: 10px;
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;

  @include tablet {
    font-size: 20px;
    width: 255px;
    height: 138px;
  }
  @include laptop {
    width: 283px;
    height: 188px;
    margin: 0;
  }
}

.borderbottom {
  border-bottom: 1px solid #eeba34;
  padding-bottom: 0.4rem;
}

.colImg {
  display: flex;
  flex-direction: row;
  @include tablet {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.8em;
  }
  @include laptop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.8em;
  }
}

.playgroundcomand {
  position: relative;
}

.waterSystem {
  display: none;

  @include laptop {
    width: 100%;
    height: 188px;
    display: block;
    object-fit: cover;
  }
}

.videoSurveillanceComand {
  display: none;
  @include laptop {
    display: block;
    position: relative;
  }
}
.waterSystemComand {
  display: none;
  @include laptop {
    display: block;
    position: relative;
  }
}

.videoSurveillance {
  display: none;
  @include laptop {
    display: block;
    width: 100%;
    height: 188px;
    object-fit: cover;
  }
}

.playground {
  position: absolute;
  top: 5.3rem;
  left: 1.2rem;
  font-size: 12px;

  @include tablet {
    top: 6.5rem;
    left: 1rem;
  }

  @include laptop {
    top: 8.125rem;
    left: 0.625rem;
  }
}

.slider {
  gap: 0.93rem;
}
