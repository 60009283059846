// BACKGROUND COLOUR

$primary-dark: #1F1D1A;
$secondary-dark: #2C2B29;

// TEXT COLOUR

$primary-white: white;
$secondary-black: #1F1D1A;

$secondary-gray: #FFFFFF80;
$secondary-gray2:#292929;
$secondary-red:red;

$secondary-font-color-black:black;

// WIDTH

$primary-width-mobile:  22.5rem;
$primary-width-tablet:  45rem;
$primary-width-laptop:  75rem;

$primary-font-size-sm: 0.875rem;
$secondary-font-size-sm: 0.625rem;
$primary-font-size-md: 1rem;
$font-size-lg: 1.8rem;

$heading-primary-font-size-sm: 1.25rem;
$heading-primary-font-size-md: 2rem;
$heading-primary-font-size-lg: 36px;

