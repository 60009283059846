@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $secondary-black;
}

.wrapper {
  color: $primary-white;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: $primary-width-mobile;
  margin: 40px 0px 40px 0px;

  overflow: hidden;
  @include tablet {
    margin: 80px 0px 80px 0px;
    padding-bottom: 0;
    width: $primary-width-tablet;
  }

  @include laptop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: $primary-width-laptop;
    margin: 140px 0px 140px 0px;
  }
}

.img {
  width: $primary-width-mobile;
  height: 15rem;

  @include tablet {
    width: $primary-width-tablet;
    height: 23.75rem;
  }
  @include laptop {
    width: 36.75rem;
    height: 22.5rem;
  }
}

.text {
  order: -1;

  @include laptop {
    order: 0;
    margin-left: 126px;
  }
}

.h1 {
  font-size: $heading-primary-font-size-sm;
  text-align: start;
  margin: 0 ;
  font-weight: 600;
  font-family: "Noto Sans";
  line-height: 49px;

  @include tablet {
    font-size: $heading-primary-font-size-md;
  }
  @include laptop {
    font-size: $heading-primary-font-size-lg;
  }
}

.paragraph {
  opacity: 60%;
  font-size: $primary-font-size-sm;
  line-height: 24px;
  font-weight: 400;
  @include tablet {
    margin: 24px 0 24px;
    font-size: $primary-font-size-md;
  }
}

.paragraph2 {
  display: none;
  @include tablet {
    display: inline;
  }
}

.orange {
  color: #ffc42c;
  cursor: pointer;
  font-family: "Asimov";
  @include tablet {
    display: none;
  }
}
.hidden {
  display: none;
  @include tablet {
    display: none;
  }
}
