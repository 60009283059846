@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 2;
  max-width: 84vh;
  width: 100%;
  max-height: 90vh;
  object-fit: contain;
  height: 100%;
}

.close {
  position: absolute;

  display: block;
  background: none;
  border: none;
 
  padding: 0;
  z-index: 3;
  width: 24px;
  height: 24px;
  top: 16px;
  right: 16px;

  @include tablet {
    width: 32px;
    height: 32px;
    top: 20px;
    right: 20px;
  }

  @include laptop {
    top: 32px;
    right: 32px;
    width: 64px;
    height: 64px;
  }

  img {
   
    width: 16px;
    height: 16px;

    @include tablet {
        width: 20px;
        height: 20px;
    }

    @include laptop {
        width: 32px;
    height: 32px;
    }
  }
}
