@import 'src/styles/variables';
@import 'src/styles/_mixins.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $secondary-dark;
  position: fixed;
  z-index: 2;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: $primary-width-mobile;
  padding: 0.25rem 0rem 0.25rem 0rem;

  @include tablet {
    width: $primary-width-tablet;
    padding: 1rem 0rem 1rem 0rem;
  }
  @include laptop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $primary-width-laptop;
    padding: 1.5rem 0rem 1.5rem 0rem;
  }

  .wrapperMobail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include laptop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .phone {
    display: block;
    margin: 15px;
    @include tablet {
      display: none;
    }
  }

  .text {
    font-weight: 400;
    line-height: 16px;
    opacity: 60%;
    color: $primary-white;
    display: none;
    cursor: pointer;
    @include laptop {
      display: flex;
      gap: 20px;
    }
  }
}

.logoLaptop {
  width: 3rem;
  @include tablet {
    width: 4.5rem;
  }
  @include laptop {
    width: 5rem;
  }
}

.active {
  border-bottom: 1px solid #ffc42c;
  padding: 2px;
  &:hover {
    color: #ffd465;
  }
}
.notActive {
  border: none;
  padding: 2px;
  &:hover {
    color: #ffd465;
  }
}

.divBtn {
  display: none;

  @include tablet {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }

  @include laptop {
    width: auto;
  }
}

.btn {
  width: 11rem;
  height: 2.7rem;
  border: none;
  background: linear-gradient(
    99.45deg,
    #eeba34 25.18%,
    #ffd465 37.87%,
    #ffc42c 46.89%,
    #ffd465 55.24%,
    #eeba34 71.6%,
    #f8b638 86.63%
  );

  @include tablet {
    margin-right: 53px;
    width: 9rem;
    height: 2rem;
  }
  @include laptop {
    margin-right: 0;
    width: 9rem;
    height: 2rem;
  }
}

.burgerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 2rem;
  height: 2rem;
  @include laptop {
    display: none;
  }
}
.burgerMobail {
  display: block;
  @include tablet {
    display: none;
  }
}
.burgerLaptop {
  display: none;
  @include tablet {
    display: block;
  }
}

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}

.textMobail {
  display: flex;
  font-weight: 400;
  line-height: 16px;
  opacity: 60%;
  flex-direction: column;
  background-color: $secondary-dark;
  color: $primary-white;
  margin-top: 1.25rem;
  padding-bottom: 1rem;
  gap: 1rem;
  cursor: pointer;
  @include laptop {
    display: none;
  }
}
