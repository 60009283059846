@import 'src/styles/variables';
@import 'src/styles/_mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Mina:wght@400;700&display=swap');

.cardResidence {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(../img/pattern.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  @include tablet {
    height: 100vh;
  }
}
.h1 {
  font-family: 'Mina', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: white;
  margin:  20px 0;
  position: relative;
  z-index: 9;
  text-transform: uppercase;
    @include tablet {
  margin: 0 0 40px;
  }
}
.wrapper {
  color: $primary-white;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  width: $primary-width-mobile;
  padding: 20px;
  position: relative;
  z-index: 1;
  @include tablet {
    // width: $primary-width-tablet;
    flex-direction: row;
    gap: 32px;
  }
  @include laptop {
    width: $primary-width-laptop;
    grid-template-columns: repeat(3, 1fr);
  }
}
a {
  text-decoration: none;
  color: $primary-white;
}

.grand {
  background-color: #2a2b2f;
  width: 328px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  padding: 24px;
  margin: 10px;
  box-shadow: 0px 4px 24px 0px #0000001f;
  border-radius: 12px;
  border: 1px solid #edb64d;
  box-sizing: border-box;
  @include tablet {
    width: auto;
    justify-content: space-between;
    align-items: start;
    padding: 32px;
    margin: 0;
    height: 248px;
  }
  @include laptop {
    width: auto;
  }
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.paragraph {
  font-family: 'Mina', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0 0;
  text-transform: uppercase;
  @include laptop {
    line-height: 28px;
    margin: 0;
  }
}

.grandLogo {
  width: auto;
  height: 64px;
  @include laptop {
    width: auto;
    height: 64px;
  }
}

.plazaLogo {
  width: auto;
  height: 64px;
  @include laptop {
    width: auto;
    height: 64px;
  }
}
