@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.mainBaner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $secondary-black;
}

.wrapper {
  color: $primary-white;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: $primary-width-mobile;
  margin-top: 4rem;
  overflow: hidden;
  @include tablet {
    width: $primary-width-tablet;
    margin-top: 7rem;
  }
  @include laptop {
    margin-top: 231px;
  }

  @include laptop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: $primary-width-laptop;
  }

  .h1 {
    font-family: 'Noto Sans';
    font-size: 30px;
    font-weight: 600;
    line-height: 93px;
    margin: 10px 0 10px 0;
    text-align: center;

    @include tablet {
      margin: 40px 0 24px 0;
      font-size: 3.5rem;
      text-align: start;
    }
    @include laptop {
      font-size: 4.25rem;
    }
  }
}

.grand {
  border-bottom: 2px solid #ffd465;
  @include laptop {
    border: 0;
  }
}

.shoh {
  @include laptop {
    display: inline-block;
    padding-right: 53px;
    border-top: 2px solid #eeba34;
  }
}

.paragraph {
  font-size: $primary-font-size-sm;
  opacity: 60%;
  font-weight: 400;
  line-height: 24px;
  @include tablet {
    font-size: $primary-font-size-md;
  }
}

.btn {
  width: 9rem;
  height: 2rem;
  margin: 0 0px 16px 0px;
  border: none;
  color: $secondary-font-color-black;
  background: linear-gradient(
    99.45deg,
    #eeba34 25.18%,
    #ffd465 37.87%,
    #ffc42c 46.89%,
    #ffd465 55.24%,
    #eeba34 71.6%,
    #f8b638 86.63%
  );

  @include tablet {
    width: 171px;
    margin-bottom: 24px;
    height: 40px;
    margin-top: 8px;
  }
  @include laptop {
    width: 243px;
    height: 44px;
  }
}
.mySwiper {
  :global(.swiper-pagination) {
    :global(.swiper-pagination-bullet) {
      background-color: #ffc42c;
      width: 20px;
      height: 2px;
      border-radius: 0;
      @include tablet {
        width: 30px;
      }
    }
  }
}

.img {
  width: $primary-width-mobile;
  height: 17.5rem;

  @include tablet {
    width: $primary-width-tablet;
    height: 26.875rem;
  }
  @include laptop {
    width: 43.125rem;
    height: 37.5rem;
  }
}
