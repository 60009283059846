@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,500;0,600;0,700;1,400&display=swap");
@import "styles/fonts";
body {
  margin: 0;
  font-family: 'Noto Sans';
}
.react-viewer-image{
  
  background: #FFF;

}
