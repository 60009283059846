@font-face {
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url('../../public/assets/fonts/notosans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Semi Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url('../../public/assets/fonts/notosansbold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Asimov';
    src: url('../../public/assets/fonts/Asimov.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
