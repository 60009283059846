@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.photoGallery {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-dark;
}

.wrapper {
  color: $primary-white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: $primary-width-mobile;
  overflow: hidden;

  @include tablet {
    width: $primary-width-tablet;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  @include laptop {
    margin-top: 140px;
    width: $primary-width-laptop;
  }
}

.swiper {
  height: 100px !important;

  @include tablet {
    height: 200px !important;
  }
  @include laptop {
    height: 500px !important;
  }

  :global(.swiper-wrapper) {
    align-items: center;
  }

  :global(.swiper-slide) {
    height: fit-content;
    // width: 300px !important;
  }
}

.h1 {
  font-weight: 600;
  font-size:20px;
  margin: 0 0 16px;
  line-height: 49px;
  @include tablet{
    margin: 0 0 0px;
    font-size: 32px;
  }
  @include laptop{
    font-size: $heading-primary-font-size-lg;

  }
}

.home {
  display: none;
  @include tablet {
    display: block;
    overflow: hidden;
    width: 100%;
  }
}
.mobailHome {
  max-width: 100%;
  display: block;
  @include tablet {
    display: none;
    overflow: hidden;
    width: 100%;
  }
  @include laptop {
    width: 100%;
    height: 100%;
  }
}

.photo {
  padding-bottom: 65%;
  position: relative;
  transition: all 250ms;
}

.backdrop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: all 250ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoGallery1,
.photoGallery2,
.photoGallery3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.photoGallery1 {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.photoGallery2 {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.photoGallery3 {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper {
  height: 100;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper,
.swiper-slide {
  width: 156px;
  height: 121px;
  // opacity: 0.4;
  object-fit: cover;
  img {
    opacity: 0.4;
  }
}

.swiper-slide {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.mySwiper2 {
  max-height: 200px;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 48px;
  position: sticky;
  z-index: 9;
  margin-top: -22px;
  z-index: 9;
}
.arrow_button {
  background-color: transparent;
  img {
    width: 24px;
    object-fit: contain;
  }
}
.swiper {
  @include tablet {
    :global(.swiper-button-prev) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(./../img/logo/arrowL.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 13px;
      width: 40px;
      height: 9px;
      bottom: 0 !important;
      position: sticky;
      left: 81%;
      &::after {
        font-size: 0;
      }
    }
    :global(.swiper-button-next) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(./../img/logo/arrowR.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 9px;
      bottom: 0px;
      position: sticky;
      left: 100%;
      &::after {
        font-size: 0;
      }
    }
    :global(.swiper-pagination) {
      max-width: max-content;
      position: absolute;
      left: 88%;
    }
  }
  @include laptop {
    :global(.swiper-button-prev) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(./../img/logo/arrowL.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 12px;
      width: 40px;
      height: 9px;
      bottom: 0 !important;
      position: sticky;
      left: 89%;
      &::after {
        font-size: 0;
      }
    }
    :global(.swiper-button-next) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(./../img/logo/arrowR.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 9px;
      bottom: 0px;
      position: sticky;
      left: 100%;
      &::after {
        font-size: 0;
      }
    }
    :global(.swiper-pagination) {
      max-width: max-content;
      position: absolute;
      left: 93%;
    }
  }
}

.mySwiper {
  width: 100%;
  height: 150px;
  padding-bottom: 40px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :global(.swiper-slide-thumb-active) {
    img {
      opacity: 1 !important;
    }
  }
  :global(.swiper-pagination) {
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
